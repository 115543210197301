import React, { useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import Button from '../../../../../Components/Button'
import Spinner from '../../../../../Components/Spinner'
import { toastError, toastSuccess } from '../../../../../Components/Utils/Utils'
import { useP } from '../../../../../services/i18n'
import { GET_PRESTA_CHANGE_SITE, SAVE_PRESTATION } from '../../../queries'
import { ModalPrompt } from '../../../../../Components/ModalDialog/ModalPrompt'

type ActionChangerSiteType = {
  prestationId: number,
  selectDatas?: any[]
}

export default function ActionChangerSite({ prestationId, selectDatas = [] }: ActionChangerSiteType) {
  const p = useP();

  const [submitChangerSite, { loading: loadingChangeSite }] = useMutation(SAVE_PRESTATION);

  const [getPrestationForChangeSite] = useLazyQuery(GET_PRESTA_CHANGE_SITE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  })

  const submitAction = (value) => {
    submitChangerSite({
      variables: {
        prestation: {
          id: prestationId,
          siteId: value
        }
      }
    }).then(({ data }) => {

      if (data.savePrestation.success) {
        toastSuccess(p.t('prestations.gsm.actions.submitBonCommandeSuccessMessage'));
      } else {
        toastError(p.t('error.defaultMessage'));
      }
    })
      .catch(() => {
        toastError(p.t('error.defaultMessage'));
      });
  }

  // Modale de confirmation
  const [modaleChangeSite, setModaleChangeSite] = useState(false)
  const [siteId, setSiteId] = useState("");

  const onClickChangerSite = () => {
    getPrestationForChangeSite({
      variables: {
        filters: [{
          column: "id",
          value: prestationId.toString()
        }]
      },
      onCompleted(data) {
        if (data?.prestations?.items.length > 0) {
          setSiteId(data.prestations.items[0].site.id)
        }
        setModaleChangeSite(true)
      },
    })
  }

  return <>
    <div className=''>
      <Button
        disabled={loadingChangeSite}
        color='primary'
        size='small'
        onClick={onClickChangerSite}>
        {loadingChangeSite ? <Spinner /> : p.t('prestations.gsm.actions.changeSiteLigne')}
      </Button>
    </div>

    <ModalPrompt
      inputProps={{
        type: 'select',
        label: p.t('prestations.gsm.actions.promptChangeSiteLabel')
      }}
      title={p.t('prestations.gsm.actions.promptChangeSite')}
      isOpen={modaleChangeSite}
      onClose={(action, value) => {
        if (action) {
          submitAction(value);
        }
        setModaleChangeSite(false)
      }}
      selectDatas={selectDatas}
      value={siteId}
    />
  </>
}