import React, { useContext, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form';
import { Input } from '../../../Components/Form/Input';
import InputSwitch from '../../../Components/Form/InputSwitch';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useP } from '../../../services/i18n';
import Spinner from '../../../Components/Spinner';
import { GET_DONNEES_REFERENCE_MODALE, GET_COMPTES } from './queries';
import { useQuery } from '@apollo/client';
import { GET_CONTACTS } from '../../Contacts/queries';
import Constants from '../../../commons/Constants'
import { useCheckRight } from '../../../Components/CheckRight/CheckRight';
import { authContext } from '../../../AuthContext';
import InputButtonsGroup from '../../../Components/Form/InputButtonsGroup/InputButtonsGroup';
import { ModalConfirmation } from '../../../Components/ModalDialog/ModalConfirmation'
import TagsInput from '../../../Components/TagsInput';
import InputSelectPrestation from './InputSelectPrestation';
import InputSelectTicket from './InputSelectTicket';
import InputSelectSite from './InputSelectSite';
import InputSelect from '../../../Components/Form/InputSelect/InputSelect';
import FilePicker from '../../../Components/FilePicker';
import Badge from '../../../Components/Badge';
import DivEditable from '../../../Components/DivEditable';

type ModaleCreationTicketBodyType = {
  control: any,
  register: any,
  errors: any,
  setValue: any,
  getValues: any,
}

type CompteType = {
  label: any,
  value: any
}

export default function ModaleCreationTicketBody({ control, register, errors, setValue, getValues }: ModaleCreationTicketBodyType) {
  const p = useP();
  const { checkRight } = useCheckRight();

  const {
    ROLE_CONTACT_ALERTE_IMPACT,
  } = Constants;

  const {
    loggedInUser,
    selectedCompte,
    sitesRestreints,
  } = useContext(authContext)

  const [displayMessageDemande, setDisplayMessageDemande] = useState(false)
  const [displayImpact, setDisplayImpact] = useState(false)
  const [displayActiviteTotaleImpactee, setDisplayActiviteTotaleImpactee] = useState(false)
  const [activiteTotaleImpactee, setActiviteTotaleImpactee] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [siteIds, setSiteIds] = useState<string[]>([])
  const [compteId, setCompteId] = useState<number | null>(null)
  const [selectedFiles, setSelectedFiles] = useState<Array<any>>([])
  const [tabComptes, setTabComptes] = useState<Array<CompteType>>([]);

  const manageActiviteTotaleImpactee = (value) => {
    if (value === false) {
      setConfirmOpen(true)
    } else {
      confirmActiviteTotaleImpactee(false)
    }
  }

  const confirmActiviteTotaleImpactee = (cancel) => {
    if (cancel) {
      setActiviteTotaleImpactee(true);
      setValue('activiteTotaleImpactee', true)
    } else {
      setActiviteTotaleImpactee(false);
      setValue('activiteTotaleImpactee', false)
    }
    setConfirmOpen(false)
  }

  const buttonsObjet = [
    {
      value: "DEMANDE", libelle: p.t('support.modaleCreation.demande')
    },
    {
      value: "INCIDENT", libelle: p.t('support.modaleCreation.incident')
    },
  ]

  const { loading: loadingRef, data: dataDonneesReference } = useQuery(GET_DONNEES_REFERENCE_MODALE);
  const { loading: loadingContacts, data: dataContacts } = useQuery(GET_CONTACTS, {
    variables: {
      filters: [
        {
          "column": "isActif",
          "value": "1"
        },
        {
          "column": "isAccesSupport",
          "value": "1"
        },
      ],
      sorters: [
        {
          "column": "nom",
          "order": "ASC"
        },
        {
          "column": "prenom",
          "order": "ASC"
        }]
    }
  });

  const { loading: loadingComptes, data: dataComptes } = useQuery(GET_COMPTES, {
    variables: {
      filters: [{
        column: "id",
        operator: "IN",
        value: selectedCompte
      }],
      sorters: [{
        column: "raisonSociale",
      }]
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  // Liste des contacts
  const tabContacts: any[] = [];
  dataContacts?.contacts?.items?.map((contact) => {
    let element = contact.nom + ' ' + contact.prenom
    element = element.replaceAll('null', ' ')
    tabContacts.push(
      {
        value: contact,
        label: element
      }
    )
  })

  // Liste des criticités
  const buttonsCriticite: any[] = [];
  dataDonneesReference?.ticketCriticites?.map((criticite) => {
    buttonsCriticite.push(
      {
        value: criticite.id,
        libelle: criticite.libelle
      }
    )
  })

  // Liste des impacts
  const buttonsImpact: any[] = [];
  dataDonneesReference?.ticketImpacts?.map((impact) => {
    buttonsImpact.push(
      {
        value: impact.id,
        libelle: impact.libelle
      }
    )
  })

  const onSelectObjet = (value) => {
    (value === "DEMANDE") ? setDisplayMessageDemande(true) : (setDisplayMessageDemande(false));
    (value === "INCIDENT" && loggedInUser?.compte?.isVip) ? setDisplayImpact(true) : setDisplayImpact(false);
    (value === "INCIDENT" && checkRight(ROLE_CONTACT_ALERTE_IMPACT)) ? setDisplayActiviteTotaleImpactee(true) : setDisplayActiviteTotaleImpactee(false);
  }

  const onSelectedFiles = (files) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const removeFile = (index) => {
    setSelectedFiles(selectedFiles.filter((el, i) => i !== index))
  }

  useEffect(() => {
    setValue('selectedFiles', selectedFiles);
  }, [selectedFiles]);

  useEffect(() => {
    if (dataComptes?.comptes?.items?.length > 0) {
      const comptes = dataComptes.comptes.items.map((c) => { return { value: c.id, label: c.raisonSociale } })
      setTabComptes(comptes);
      setValue('compte', comptes.length === 1 ? comptes[0] : null);
    }
  }, [dataComptes]);

  return (
    <>
      {loadingRef || loadingContacts || loadingComptes ?
        <><div className='h-[430px] w-full flex items-center justify-center'><Spinner color='primary' /></div></>
        :
        <div className='flex flex-col space-y-6'>
          <div className='flex flex-col space-y-3'>
            <div className='flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4 bg-gray-100 p-2 rounded-md'>
              <div className='flex-1 flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:space-x-3'>
                <div className=''>
                  <label className="block text-sm font-medium text-gray-700 mb-1">{p.t('support.modaleCreation.objet')} *</label>
                  <Controller
                    control={control}
                    name="objet"
                    render={({ field }) =>
                      <InputButtonsGroup
                        onSelectedButton={(value) => {
                          onSelectObjet(value);
                          field.onChange(value)
                        }}
                        selectedButtons={field.value}
                        buttons={buttonsObjet}
                        multi={false}
                        name="objet"
                        errors={errors}
                        forceHorizontalOnMobile
                      />}
                  />
                </div>

                <div className=''>
                  <label className="block text-sm font-medium text-gray-700 mb-1">{p.t('support.modaleCreation.criticite')} *</label>
                  <Controller
                    control={control}
                    name="criticite"
                    render={({ field }) =>
                      <InputButtonsGroup
                        onSelectedButton={(value) => {
                          field.onChange(value)
                        }}
                        selectedButtons={field.value}
                        buttons={buttonsCriticite}
                        multi={false}
                        name="criticite"
                        errors={errors}
                        forceHorizontalOnMobile
                      />}
                  />
                </div>
                {displayImpact &&
                  <div className=''>
                    <label className="block text-sm font-medium text-gray-700 mb-1">{p.t('support.modaleCreation.impactProd')}</label>
                    <Controller
                      control={control}
                      name="impact"
                      render={({ field }) =>
                        <InputButtonsGroup
                          onSelectedButton={(value) => {
                            field.onChange(value)
                          }}
                          selectedButtons={field.value}
                          buttons={buttonsImpact}
                          multi={false}
                          name="impact"
                          errors={errors}
                        />}
                    />
                  </div>
                }
              </div>
              {displayActiviteTotaleImpactee &&
                <div className='flex items-center space-x-2 my-0 border border-gray-300 bg-white rounded-md pr-2'>
                  <div className='h-full flex items-center justify-center border-r border-gray-300 p-3 bg-gray-100 rounded-l-md'>
                    <ExclamationTriangleIcon className='text-red-600 h-5 w-5' />
                  </div>
                  <Controller
                    control={control}
                    name="activiteTotaleImpactee"
                    render={({ field }) =>
                      <InputSwitch
                        {...field}
                        label={p.t('support.modaleCreation.activiteTotaleImpactee')}
                        name="activiteTotaleImpactee"
                        value={activiteTotaleImpactee}
                        onClick={(val) => {
                          manageActiviteTotaleImpactee(val)
                          field.onChange(val)
                        }}
                        labelPosition='before'
                        bordered
                        manualOnChange
                      />
                    }
                  />
                </div>
              }
            </div>
            {
              displayMessageDemande &&
              <div className={clsx(`text-sm text-gray-700 bg-gray-100 rounded-md p-2`)}>
                <div>{p.t('support.modaleCreation.msgRarifsDemande_1')}</div>
                <div>{p.t('support.modaleCreation.msgRarifsDemande_2')}</div>
              </div>
            }

            <div>
              <Controller
                control={control}
                name="sujet"
                render={({ field }) =>
                  <Input
                    register={register}
                    errors={errors}
                    name="sujet"
                    label={`${p.t('support.modaleCreation.sujet')}  *`}
                    type='text'
                    onChange={(e) => field.onChange(e.target.value)}
                    value={field.value}
                    autoComplete='off'
                  />} />
            </div>

            <div className='flex flex-col lg:flex-row lg:space-x-3'>
              <div className='grow-0 lg:w-8/12'>
                <label className="block text-sm font-medium text-gray-700 mb-1">{p.t('support.modaleCreation.description')} *</label>
                <Controller
                  control={control}
                  name="description"
                  render={({ field, fieldState: { error } }) => {
                    return <DivEditable
                      onChange={(e) => {
                        setValue('description', e.target["innerHTML"])
                        field.onChange(e.target["innerHTML"])
                      }}
                      textareaHeight="100px"
                      containerHeight={500}
                      margin={0}
                      error={error}
                    />
                  }}
                />
              </div>
              <div className='flex flex-col space-y-2 mt-6 grow-0 lg:w-4/12'>
                <div>
                  <Controller
                    control={control}
                    name="selectedFiles"
                    render={() =>
                      <>
                        <div className="flex items-start space-x-2">
                          <FilePicker multiple={true} onFilesSelected={(files) => onSelectedFiles && onSelectedFiles(files)} tooltipContent={p.t('components.PJ.addPJTooltip')} filetypes="" />
                          <div className='flex items-center flex-wrap truncate'>
                            {
                              selectedFiles.length > 0 &&
                              <>
                                <div className='flex items-center flex-wrap'>
                                  {
                                    selectedFiles.map((file, i) => {
                                      return <div className='mr-2 mb-1' key={i}>
                                        <Badge key={i} rounded={false} deleteAction={() => removeFile(i)} deletable itemColor='gray' itemLibelle={<><div>{file.name}</div></>} />
                                      </div>
                                    })
                                  }
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      </>
                    }
                  />

                </div>
                <div>
                  <Controller
                    control={control}
                    name="mailsSuiveurs"
                    render={() =>
                      <TagsInput
                        placeholder={p.t('support.detail.cc.placeholder')}
                        type="email"
                        inputClassName="w-[155px]"
                        onChange={(tags) => setValue('mailsSuiveurs', tags.join(";"))}
                        defaultTags={getValues('mailsSuiveurs') ? getValues('mailsSuiveurs').split(';') : []}
                      />
                    }
                  />
                </div>
              </div>
            </div>

          </div>
          <div className='flex-1 flex flex-col space-y-3 bg-gray-100 p-2 rounded-md'>
            <div className='lg:flex lg:space-x-2'>
              <div className='flex-1'>
                <Controller
                  control={control}
                  name="contactSite"
                  render={({ field }) =>
                    <InputSelect
                      {...field}
                      options={tabContacts}
                      value={getValues("nomContactSite") && !getValues("contactSite") ? tabContacts.find(c => c.label === getValues("nomContactSite")) : tabContacts.find(c => c.value === field.value)}
                      label={p.t('support.modaleCreation.contactSite')}
                      onChange={(contact) => {
                        if (contact) {
                          const telephone = contact?.value.telephoneMobile ? contact?.value.telephoneMobile : contact?.value.telephoneFixe
                          setValue('numContactSite', telephone?.toUpperCase().replace(/\s/g, ''))
                        }
                        setValue('nomContactSite', contact?.label)
                        field.onChange(contact?.label)
                      }}
                      create
                      menuPlacement="top"
                      placeholder=''
                      isMulti={false}
                    />
                  }
                />
              </div>
              <div className='w-[180px]'>
                <Controller
                  control={control}
                  name="numContactSite"
                  render={({ field }) =>
                    <Input
                      register={register}
                      errors={errors}
                      name="numContactSite"
                      label={`${p.t('support.modaleCreation.numeroContactSite')} *`}
                      type='text'
                      autoComplete='off'
                      onChange={(e) => {
                        setValue('numContactSite', e.target.value.toUpperCase().replace(/\s/g, ''))
                        field.onChange(e.target.value)
                      }}
                      value={field.value}
                    />}
                />
              </div>
              <div className='flex-1'>
                <Controller
                  control={control}
                  name="refInterne"
                  render={({ field }) =>
                    <Input
                      register={register}
                      errors={errors}
                      name="refInterne"
                      label={`${p.t('support.modaleCreation.refInterne')}`}
                      type='text'
                      autoComplete='off'
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                    />
                  }
                />
              </div>
            </div>
            <div className='lg:flex lg:space-x-2'>

              <div className='flex-1'>
                {tabComptes.length === 1
                  ? <>
                    <div className='block text-sm font-medium text-gray-700 mb-1'>{p.t('support.modaleCreation.compte')}</div>
                    <div className='bg-gray-50 text-gray-700 text-sm  rounded-md py-2 px-2 border border-gray-50'>{tabComptes[0].label}</div>
                  </>
                  :
                  <Controller
                    control={control}
                    name="compte"
                    defaultValue={tabComptes.length === 1 ? tabComptes[0] : null}
                    render={({ field }) => <InputSelect
                      errors={errors}
                      options={tabComptes}
                      label={`${p.t('support.modaleCreation.compte')}  *`}
                      placeholder=''
                      menuPlacement="top"
                      {...field}
                      onChange={(e) => {
                        setCompteId(parseInt(e?.value))
                        setValue("sites", [])
                        setValue("prestations", [])
                        field.onChange(e)
                      }}
                    />}
                  />
                }
              </div>
              <div className='flex-1'>
                <Controller
                  control={control}
                  name="sites"
                  render={({ field }) => <InputSelectSite
                    label={`${p.t('support.modaleCreation.site')}${sitesRestreints && sitesRestreints?.length > 0 ? ' *' : ''}`}
                    errors={errors}
                    compteId={compteId}
                    {...field}
                    onChange={(e) => {
                      setSiteIds(e.map(s => s.value))
                      setValue("prestations", [])
                      field.onChange(e)
                    }}
                  />}
                />
              </div>
              <div className='flex-1'>
                <Controller
                  control={control}
                  name="prestations"
                  render={({ field }) => <InputSelectPrestation
                    siteIds={siteIds}
                    compteId={compteId}
                    {...field}
                  />}
                />
              </div>
            </div>

            <div className='flex flex-col space-y-2 lg:flex-row lg:items-center lg:space-x-3 lg:space-y-0'>
              <div className=''>
                <Controller
                  control={control}
                  name="recurrence"
                  render={() =>
                    <InputSwitch
                      name="recurrence"
                      label={`${p.t('support.modaleCreation.pbmRecurrent')}`}
                      value={false}
                      onChange={(value) => setValue('recurrence', value)}
                      labelPosition='before'
                      bordered
                    />
                  }
                />
              </div>
              <div className='flex-1'>
                <Controller
                  control={control}
                  name="ticketPrecedent"
                  render={({ field }) => <InputSelectTicket {...field} />}
                />
              </div>
            </div>

          </div>
          <ModalConfirmation
            isOpen={confirmOpen}
            closeIcon={false}
            message={`${p.t('support.modaleCreation.confirmActiviteTotaleImpactee')}`}
            onClose={(cancel) => confirmActiviteTotaleImpactee(cancel)}
            okButtonLabel={`${p.t('support.modaleCreation.cancelButtonLabel')}`}
            cancelButtonLabel={`${p.t('support.modaleCreation.okButtonLabel')}`}
          />
        </div >
      }
    </>)
}