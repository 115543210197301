import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Constants from "../../../../../commons/Constants";
import Badge from "../../../../../Components/Badge";
import BadgeValue from "../../../../../Components/BadgeValue";
import Button from "../../../../../Components/Button";
import { useCheckRight } from "../../../../../Components/CheckRight/CheckRight";
import DescriptionList, { DescriptionListItemAlignType, DescriptionListItemType } from "../../../../../Components/DescriptionList/DescriptionList";
import Editable from "../../../../../Components/Editable/Editable";
import Modal from "../../../../../Components/Modal/Modal";
import { toastError } from "../../../../../Components/Utils/Utils";
import { useP } from "../../../../../services/i18n";
import PrestationHistoriqueModaleBody from "../../../PrestationDetail/PrestationHistoriqueModaleBody";
import { SAVE_LIGNE } from "../../../queries";
import GSMDetailModaleBody from "./GSMDetailModaleBody";
import GSMActions from "./GSMActions";
import GSMOptions from "./GSMOptions";
import convert from "convert";
import PrestationContact from "../../PrestationContact";
import ActionInformationsBalanceData from "./ActionInformationsBalanceData";
import TooltipInformation from "../../../../../Components/Tooltip/TooltipInformation";

type GSMDetailModaleType = {
  prestation: any,
  isOpen: boolean,
  onClose: () => void,
  onSave: (gsm: any) => void
}

const {
  ROLE_ADMIN_PRESTATION,
  ROLE_ADMIN_ANNUAIRE,
  GSM_FOURNISSEUR_EIT
} = Constants;

export default function GSMDetailModale({ prestation, isOpen = false, onClose, onSave }: GSMDetailModaleType) {
  const p = useP();
  const { checkRight } = useCheckRight();
  const [saveLigne] = useMutation(SAVE_LIGNE);

  const [prestationAffiche, setPrestationAffiche] = useState<any>(prestation);
  const [tabs, setTabs] = useState<any>({ navigation: [], panels: [] });
  const topAlign: DescriptionListItemAlignType = "top"


  const rightAdminPrestation = checkRight(ROLE_ADMIN_PRESTATION);
  const rightAdminAnnuaire = checkRight(ROLE_ADMIN_ANNUAIRE);

  const onSaveLigne = async (ligne) => {
    let result = true;
    await saveLigne({
      variables: { ligne: { id: prestationAffiche?.ligne?.id, ...ligne } },
      onCompleted: (data) => {
        setPrestationAffiche({
          ...prestationAffiche,
          ligne: {
            ...prestationAffiche.ligne,
            ...ligne
          }
        })
        onSave({ ligne: data.saveLigne })
      },

    }).catch((error) => {
      console.error(error)
      toastError(p.t('error.defaultMessage'));
      result = false
    })

    return result;
  }

  const [modalTitleContactName, setModalTitleContactName] = useState(`${prestationAffiche?.contact?.prenom} ${prestationAffiche?.contact?.nom}`)
  const onSaveContactCompleted = (contact) => {
    setPrestationAffiche({
      ...prestationAffiche,
      contact: {
        ...prestationAffiche.contact,
        ...contact
      }
    });
    setModalTitleContactName(`${contact.prenom} ${contact.nom}`)
    onSave({
      ...prestationAffiche,
      contact: {
        ...prestationAffiche.contact,
        ...contact
      }, idPrestation: parseInt(prestation?.id)
    })
  }

  useEffect(() => {
    setPrestationAffiche(prestation)
    setModalTitleContactName(`${prestation?.contact?.prenom} ${prestation?.contact?.nom}`)
  }, [prestation])

  useEffect(() => {

    //Mise en forme des volumes
    const duration = dayjs.duration(prestationAffiche?.ligne ? prestationAffiche?.ligne.voix * 1000 : 0);
    const convertData = convert(prestationAffiche?.ligne ? prestationAffiche?.ligne.data : 0, 'KiB').to('best');

    const dataFormate = `${parseFloat(convertData.quantity.toString()).toLocaleString()} ${convertData.quantity === 0 ? 'Ko' : convertData.unit.replace('iB', 'o')}`;
    const voixFormate = `${duration.hours()} h ${duration.minutes()} m ${duration.seconds()} s`;

    const infosGSM_Left = [
      {
        label: p.t('prestations.gsm.detail.infos.MSISDN'),
        value: <div className="py-0.5">{prestationAffiche?.numero}</div>
      },
      {
        label: p.t('prestations.gsm.detail.infos.etatLigne'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.etat}</div>
      },
      {
        label: p.t('prestations.gsm.detail.infos.contact'),
        align: topAlign,
        value: rightAdminAnnuaire
          ? <>
            <PrestationContact
              prestation={prestationAffiche}
              onSaveCompleted={(contact) => onSaveContactCompleted(contact)}
            />
          </>
          : <>
            <div>
              <div>{prestationAffiche?.contact?.nom} {prestationAffiche?.contact?.prenom}</div>
              <div className="text-gray-600 text-xs">{prestationAffiche?.contact?.mail}</div>
            </div>
          </>
      },
      {
        label: p.t('prestations.gsm.detail.infos.forfait'),
        value: <div className="py-0.5">{prestationAffiche?.forfait}</div>
      },
      prestationAffiche?.abonnementLivraisonId &&
      {
        label: <div className="flex items-center space-x-1 justify-between w-full">
        <div>{p.t('prestations.gsm.detail.infos.rechargeAuto')}</div>
        <div>
          <TooltipInformation sizeClass="h-5 w-5" contenu={
            <div className="max-w-[400px]">
              <div>{p.t('prestations.gsm.detail.infos.infos_recharge_data_1')}</div>
              <div>{p.t('prestations.gsm.detail.infos.infos_recharge_data_2')}</div>
            </div>
          }
          />
        </div>
      </div>,
        align: topAlign,
        value: rightAdminPrestation
          ? <>
            <ActionInformationsBalanceData
              prestationAffiche={prestationAffiche}
              onSaveCompleted={(ligne) => onSaveLigne(ligne)}
            />
          </>
          : <>
            <div>
              <div>{parseInt(prestationAffiche?.ligne?.rechargeAuto) ? `Oui, quand l'enveloppe DATA a atteint ${prestationAffiche?.ligne?.rechargeAuto}%` : 'Non'} </div>
            </div>
          </>
      }
      ,
      {
        label: p.t('prestations.gsm.detail.infos.support'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.gsmHno?.nom}</div>
      },
      {
        label: <div className="flex items-center space-x-1 justify-between w-full">
          <div>{p.t('prestations.gsm.detail.infos.consommations')}</div>
          <div>
            <TooltipInformation sizeClass="h-5 w-5" contenu={
              <div className="max-w-[400px]">
                <div>{p.t('prestations.gsm.detail.infos.infos_conso_1')}</div>
                <div>{p.t('prestations.gsm.detail.infos.infos_conso_2')}</div>
              </div>
            }
            />
          </div>
        </div>,
        value:
          <div className="flex flex-col items-start space-y-2">
            <div><BadgeValue data={{ label: p.t('prestations.gsm.detail.infos.voix'), value: voixFormate }} color="indigo" /></div>
            <div className=""><BadgeValue data={{ label: p.t('prestations.gsm.detail.infos.data'), value: dataFormate }} color="sky" /></div>
          </div>,
        align: topAlign
      },
      {
        label: p.t('prestations.gsm.detail.infos.affectation'),
        value: rightAdminPrestation
          ? <Editable value={prestationAffiche?.ligne?.affectation} onSaveField={(value) => onSaveLigne({ affectation: value })} />
          : prestationAffiche?.ligne?.affectation
      },
      {
        label: p.t('prestations.gsm.detail.infos.matricule'),
        value: rightAdminPrestation
          ? <Editable value={prestationAffiche?.ligne?.matricule} onSaveField={(value) => onSaveLigne({ matricule: value })} />
          : prestationAffiche?.ligne?.matricule
      },
    ]

    const infosGSM_Right = [
      {
        label: p.t('prestations.gsm.detail.infos.ICCID'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.gsmSim?.simsnFull}</div>
      },
      {
        label: p.t('prestations.gsm.detail.infos.SIMSN'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.gsmSim?.simsn}</div>
      },
      {
        label: p.t('prestations.gsm.detail.infos.PIN'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.gsmSim?.pin}</div>
      },
      {
        label: p.t('prestations.gsm.detail.infos.PIN2'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.gsmSim?.pin2}</div>
      },
      {
        label: p.t('prestations.gsm.detail.infos.PUK'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.gsmSim?.puk}</div>
      },
      {
        label: p.t('prestations.gsm.detail.infos.PUK2'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.gsmSim?.puk2}</div>
      },
      {
        label: p.t('prestations.gsm.detail.infos.datePortabilite'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.datePortabilite ? dayjs(prestationAffiche?.ligne?.datePortabilite).format("DD/MM/YYYY HH:mm:ss") : null}</div>
      },
      {
        label: p.t('prestations.gsm.detail.infos.commentaire'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.commentaire}</div>
      },
      {
        label: p.t('prestations.gsm.detail.infos.note'),
        value: rightAdminPrestation
          ? <Editable value={prestationAffiche?.ligne?.commentaireSi} onSaveField={(value) => onSaveLigne({ commentaireSi: value })} />
          : prestationAffiche?.ligne?.commentaireSi
      },

      {
        label: p.t('prestations.gsm.detail.infos.RIO'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.rioExterne}</div>
      },
      {
        label: p.t('prestations.gsm.detail.infos.dateResil'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.dateResiliation ? dayjs(prestation.ligne.dateResiliation).format("DD/MM/YYYY") : "--"}</div>
      }
    ]

    if (prestationAffiche?.ligne?.gsmSim?.fournisseurId === GSM_FOURNISSEUR_EIT) {
      infosGSM_Left.push({
        label: p.t('prestations.gsm.detail.infos.IMEI'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.currentImei} {prestationAffiche?.ligne?.imeiUpdate ? `(${dayjs(prestationAffiche.ligne.imeiUpdate).format("DD/MM/YYYY")})` : ""}</div>
      })

      infosGSM_Left.push({
        label: p.t('prestations.gsm.detail.infos.pwdMessagerie'),
        value: <div className="py-0.5">{prestationAffiche?.ligne?.passwordMessagerie}</div>
      })
    }

    const formattedInfosGSM_Left: DescriptionListItemType[] = infosGSM_Left.filter(item => typeof item !== "boolean" && item !== undefined).map(item => item as DescriptionListItemType);

    const tabsGSM = {
      navigation: [
        {
          libelle: p.t('prestations.detail.informations'),
          jsx: <>{p.t('prestations.detail.informations')}</>
        },
        {
          libelle: p.t('prestations.detail.historique'),
          jsx: <>{p.t('prestations.detail.historique')}</>
        }
      ],
      panels: [
        <div key="Infos_GSM" className="flex flex-col md:flex-row items-start md:space-x-8">
          <div className="w-full md:w-auto md:flex-1"><DescriptionList datas={formattedInfosGSM_Left} columnWidthClassName="w-auto mr-2 md:w-[150px]" /></div>
          <div className="w-full md:w-auto md:flex-1"><DescriptionList datas={infosGSM_Right} columnWidthClassName="w-auto mr-2 md:w-[190px]" /></div>
        </div>,
        <PrestationHistoriqueModaleBody key="Historique_GSM" prestationId={prestationAffiche?.id} />,
      ]

    }

    if (rightAdminPrestation && prestationAffiche?.abonnementLivraisonId && prestationAffiche?.ligne) {

      tabsGSM.navigation.push({
        libelle: p.t('prestations.detail.actions'),
        jsx: <>{p.t('prestations.detail.actions')}</>
      })

      tabsGSM.panels.push(
        <div className='py-4 lg:flex'>
          <GSMActions forfait={prestationAffiche?.forfait} compteId={prestationAffiche?.client?.compte?.id} siteId={prestationAffiche?.site.id} livraisonId={prestationAffiche?.abonnementLivraisonId} ligne={prestationAffiche?.ligne} prestationId={prestationAffiche?.id} />
          <GSMOptions livraisonId={prestationAffiche?.abonnementLivraisonId} prestationId={prestationAffiche?.id} />
        </div>
      )
    }

    setTabs(tabsGSM)
  }, [prestationAffiche])

  return <>
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      content={{
        title: <>
          <div className="flex items-center">
            <div className="">{prestationAffiche?.numero}</div>
            <div className="mx-2">-</div>
            <div className="">{modalTitleContactName}</div>
            <div className="ml-2"><Badge itemLibelle={prestationAffiche?.etat?.libelle} itemColor={prestationAffiche?.etatColor} /></div>
          </div>
          <div className="text-xs text-gray-600 mt-1">
            <div>{prestationAffiche?.site?.nom}</div>
            <div>{prestationAffiche?.site?.codePostal} {prestationAffiche?.site?.commune}</div>
          </div>
        </>
        ,
        body: <><GSMDetailModaleBody tabsNavigation={tabs.navigation} tabsPanel={tabs.panels} /></>,
        footer: <><div className='flex items-center justify-end'>
          <Button onClick={onClose}>
            {p.t('actions.fermer')}
          </Button>
        </div></>,
      }}
      className="w-full lg:h-5/6 lg:w-10/12 xl:w-8/12"
    />
  </>
}