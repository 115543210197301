import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useP } from '../../services/i18n';
import { TableOptionsType } from '../../Components/Table/Types/TableOptionsType';
import { GlobeAltIcon, PhoneIcon, ServerStackIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import DropdownMenu from '../../Components/DropdownMenu/DropdownMenu';
import ButtonDropdown from '../../Components/ButtonDropdown';
import { typeNewTicketType } from './ModaleCreationTicket/ModaleCreationTicket';
import Tooltip from '../../Components/Tooltip/Tooltip';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_TICKETS, GET_TICKETS_STATUTS } from './queries';
import { colorStateTicket } from '../../Components/Utils/Utils';
import Table from '../../Components/Table/Table';
import { authContext } from '../../AuthContext';
import { TicketStatutPublicType, TicketStatutType, TicketType } from '../../types/TicketType';
import dayjs from 'dayjs';
import ExportTicketCsv from './ExportTicketCsv';
import FilterTable from '../../Components/Table/FilterTable';
import { queryFilterType } from '../../types/queryApiType';
import ModaleDetailTicket from './ModaleDetailTicket/ModaleDetailTicket';
import { useCheckRight } from '../../Components/CheckRight/CheckRight';
import Constants from '../../commons/Constants'
import { useCheckFeatureFlag } from '../../Components/CheckFeatureFlag/CheckFeatureFlag';

type TableSupportType = {
  onClickRow: (row: any) => void | undefined,
  addTicket: (type: typeNewTicketType) => void,
  ticketIdSelected: number | null,
  onCloseModaleDetailTicket: () => void,
}

export default function TableSupport({ onClickRow, addTicket, ticketIdSelected, onCloseModaleDetailTicket, }: TableSupportType) {

  const p = useP();
  const { checkRight } = useCheckRight();
  const { checkFeatureFlag } = useCheckFeatureFlag();

  const {
    ROLE_ADMIN_SUPPORT,
    FEATURE_FLAG_SUPPORT_INTERNE,
  } = Constants;

  const defaultFilterStatuts = ["EN_ATTENTE_D_UNE_R_PONSE_DE_VOTRE_PART", "NOUVEAU", "EN_COURS_DE_TRAITEMENT"]
  const FILTER_TOUS_TICKETS = "TOUS_TICKETS";
  const FILTER_MES_TICKETS = "MES_TICKETS";

  const { loggedInUser } = useContext(authContext);

  const tableOptions: TableOptionsType = {
    resizable: true,
    naturalWidth: true,
    search: {
      placeholder: p.t('support.labelRecherche'),
      attributs: ["numero", "sujet", "qualification", "raisonSociale"]
    }
  }

  const columns = [
    {
      libelle: p.t('support.columns.numeroTicket'),
      code: 'numero',
      colType: 'string',
      sortable: true,
    },
    {
      libelle: p.t('support.columns.qualification'),
      code: 'qualification',
      colType: 'string',
      sortable: false,
      width: "160px"
    },
    {
      libelle: p.t('support.columns.statut'),
      code: 'statut',
      colType: 'state',
      sortable: false,
    },
    {
      libelle: p.t('support.columns.raisonSociale'),
      code: 'raisonSociale',
      colType: 'string',
      sortable: true,
    },
    {
      libelle: p.t('support.columns.anciennete'),
      code: 'anciennete',
      colType: 'dateFromNow',
      sortable: true,
    },
    {
      libelle: p.t('support.columns.dateModification'),
      code: 'dateModification',
      colType: 'dateTime',
      sortable: true,
    },    
    {
      libelle: p.t('support.columns.sujet'),
      code: 'sujet',
      colType: 'string',
    },
    {
      libelle: "",
      code: 'detail',
      colType: 'detail',
      action: onClickRow,
      resizable: false
    },
  ];

  const [tableDatas, setTableDatas] = useState<any[]>([]);
  const [dataFilters, setDataFilters] = useState<any[]>([]);
  const [activeFilters, setActiveFilters] = useState({
    statut: defaultFilterStatuts,
    tickets: checkRight(ROLE_ADMIN_SUPPORT) ? FILTER_TOUS_TICKETS : FILTER_MES_TICKETS
  })
  const [tableSearch, setTableSearch] = useState("");

  const { loading: loadingStatuts, data: dataStatuts, error: errorStatuts } = useQuery(GET_TICKETS_STATUTS, {
    variables: {
      sorters: [
        {
          column: "ordreClient"
        }
      ]
    }
  })

  const [getTickets, { loading: loadingTickets, data: dataTickets, error: errorTickets, refetch: refetchTicket }] = useLazyQuery(GET_TICKETS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {

    const datas = dataTickets?.tickets?.items ? dataTickets.tickets.items.map((t: TicketType) => (
      {
        id: t.id,
        numero: t.numero,
        qualification:
          (<div className='flex items-center space-x-2'>
            <div>{`${t.qualificatif} ${t.ticketCriticite.libelle}`}</div>
            {t.activiteTotaleImpactee && <Tooltip
              button={
                <ExclamationTriangleIcon className='text-red-600 h-5 w-5' />
              }
              content={<div className="text-xs">{p.t('support.activiteEntrepriseImpactee')}</div>}
              withArrow
            />}
          </div>),
        statut: {
          libelle: t.ticketStatut.statutPublic,
          colorClasses: `bg-${colorStateTicket(t.ticketStatut.id)}-200 text-${colorStateTicket(t.ticketStatut.id)}-800`
        },
        raisonSociale: t.compte.raisonSociale,
        anciennete: t.dateCreation,
        sujet: t.titre,
        dateModification: t.dateModification || t.dateCreation,
      }
    )) : []

    setTableDatas(datas);
  }, [dataTickets])

  const statutPublics = useMemo(() =>
    dataStatuts?.ticketStatutPublics
    , [dataStatuts]);

  useEffect(() => {
    const filterButtons: object[] = [];

    filterButtons.push({
      libFiltre: "Statut",
      multi: true,
      filtres: statutPublics ? statutPublics.map((tsp) => ({
        libelle: tsp.libelle,
        value: tsp.code
      }),
      ) : []
    })

    /**
     * Si l'utilisateur a le droit ROLE_ADMIN_SUPPORT, on affiche les filtres de tickets
     */
    if (checkRight(ROLE_ADMIN_SUPPORT)) {
      filterButtons.push({
        libFiltre: "Tickets",
        multi: false,
        filtres: [
          {
            libelle: `${p.t('support.filterButtons.tickets.TOUS_TICKETS')}`,
            value: FILTER_TOUS_TICKETS
          },
          {
            libelle: `${p.t('support.filterButtons.tickets.MES_TICKETS')}`,
            value: FILTER_MES_TICKETS
          },
        ]
      });
    }

    setDataFilters(filterButtons);

  }, [statutPublics])

  useEffect(() => {

    if (statutPublics) {

      const filterStatutTicket: queryFilterType[] = [{
        column: "ticketStatut.id",
        operator: "IN",
        value: statutPublics.filter((sp: TicketStatutPublicType) => activeFilters.statut.includes(sp.code))
          .reduce((a: TicketStatutType[], v: TicketStatutPublicType) => [...a, ...v.ticketStatuts], [])
          .map((s: TicketStatutType) => s.id)
      }]

      /**
       * Il faut forcer l'ajout du filtre contact_id si l'utilisateur a le droit ROLE_ADMIN_SUPPORT (cela se fait nativement dans le cas ACCES_SUPPORT)
       */
      if (checkRight(ROLE_ADMIN_SUPPORT) && activeFilters.tickets === FILTER_MES_TICKETS) {
        filterStatutTicket.push(
          {
            column: "contact_id",
            operator: "EQ",
            value: loggedInUser?.user.id || null
          }
        )
      }

      if (tableSearch) {
        filterStatutTicket.push(
          {
            column: "numero",
            operator: "LIKE",
            value: `%${tableSearch}%`,
            or: [
              {
                column: "titre",
                operator: "LIKE",
                value: `%${tableSearch}%`
              },
              {
                column: "description",
                operator: "LIKE",
                value: `%${tableSearch}%`
              }
            ]
          }
        )
      }

      getTickets({
        variables: {
          filters: [
            {
              column: "dateCloture",
              value: null
            },
            {
              column: "dateCloture",
              operator: "GTE",
              value: dayjs().add(-1, 'year').format('YYYY-MM-DD HH:mm:ss')
            },
            ...filterStatutTicket
          ],
          sorters: [
            {
              column: "ticketStatut.ordreClient"
            },
            {
              column: "dateCreation",
              order: "DESC"
            }
          ],
          includes: [
            {
              association: 'ticketCriticite',
              required: true,
            },
            {
              association: 'ticketStatut',
              required: true,
            },
            {
              association: 'compte',
              required: true,
            }
          ]
        },
      });
    }

  }, [activeFilters, statutPublics, tableSearch])


  const updateSelectedButtons = (value: string, key: string) => {
    const newFilter = {
      ...activeFilters
    };
    newFilter[key.toLowerCase()] = value;
    setActiveFilters(newFilter)
  }

  const typeTicketData: typeNewTicketType = "RÉSEAU";
  const typeTicketVoix: typeNewTicketType = "TÉLÉPHONIE";
  const typeTicketHebergement: typeNewTicketType = "HÉBERGEMENT";
  const typeTicketSupportInterne: typeNewTicketType = "SUPPORT INTERNE";

  const menuAddTicket = [
    {
      libelle: p.t('support.voix'),
      icon: <PhoneIcon />,
      func: () => addTicket(typeTicketVoix)
    },
    {
      libelle: p.t('support.data'),
      icon: <GlobeAltIcon />,
      func: () => addTicket(typeTicketData)
    },
    {
      libelle: p.t('support.hebergement'),
      icon: <ServerStackIcon />,
      func: () => addTicket(typeTicketHebergement)
    },
  ]

  if (checkFeatureFlag(FEATURE_FLAG_SUPPORT_INTERNE)) {
    menuAddTicket.push({
      libelle: p.t('support.supportInterne'),
      icon: <ExclamationCircleIcon />,
      func: () => addTicket(typeTicketSupportInterne)
    })
  }

  return <>
    <Table
      idTable='ec-table-support'
      loading={loadingTickets}
      error={errorTickets || errorStatuts ? true : false}
      datas={tableDatas}
      columns={columns}
      options={tableOptions}
      onClickRow={onClickRow}
      onSearch={(search) => setTableSearch(search)}
      customToolbarActions={
        <>
          <div className='flex items-center space-x-2 w-full justify-between'>
            {
              !loadingStatuts &&
              <FilterTable
                datas={dataFilters}
                onSelectedButtonGroup={(value, key) => updateSelectedButtons(value, key)}
                activeFilters={activeFilters}
                keyFiltreLowercase={true}
              />
            }
            <div className='flex items-center space-x-2'>
              <div className="">
                <ExportTicketCsv />
              </div>
              <DropdownMenu items={menuAddTicket} buttonComponent={
                <ButtonDropdown
                  className='w-full lg:w-auto '
                  color='primary'
                  libelle={p.t('support.addTicket')}
                />

              } />
            </div>
          </div>
        </>
      }
    />
    <ModaleDetailTicket isOpen={ticketIdSelected ? true : false} 
      onClose={()=>{
        onCloseModaleDetailTicket()
        refetchTicket()
      }}
      ticketId={ticketIdSelected} 
    />      
  </>
}