import { gql } from "@apollo/client";

const GET_SITES = gql`
  query sites ($filters: [FilterInput], $attributes: [String], $includes: [IncludeInput], $sorters: [SorterInput]){
    sites (filters: $filters, attributes: $attributes, includes: $includes, sorters: $sorters){ 
      id              
      codePostal
      commune
      complementVoie
      libelleVoie
      nom
      numeroVoie      
      prestations {
        etat
        libelle
        dateResiliation
        type      
        isVisibleEc        
      }
    }
  }`

const GET_PRESTATIONS = gql`
  query prestations ($filters: [FilterInput], $attributes: [String]){
    prestations (filters: $filters, attributes: $attributes) {                
      items{
        etat
        libelle
        dateResiliation
        type
      }
    }
  }`

const GET_PRESTATIONS_SITE = gql`
  query prestations ($filters: [FilterInput]){
    prestations (filters: $filters) {                
      items{
        site {
          id
        }
      }
    }
  }`

const GET_PRESTA_CHANGE_SITE = gql`
  query PrestaCurrentSite ($filters: [FilterInput]){
    prestations (filters: $filters) {                
      items{
        site {
          id
        }
      }
    }
  }`

const GET_LIEN = gql`
  query PrestationLiens($idSite: ID, $idContact: ID) {
    prestationLiens(idSite: $idSite, idContact: $idContact) {
      id
      etat      
      etatColor
      type
      debitDown
      debitUp
      logins
      ipWans
      loginStates
      loginStatesDates
      loginPingStates
      loginSupervisionActives
      client {
        compte {
          id
        }
      }
      site{
        id
        nom
        numeroVoie
        libelleVoie
        complementVoie
        codePostal
        commune
        latitude
        longitude   
      }
      idLien
    }
  }`

const GET_CENTILE = gql`
  query PrestationCentiles($idSite: ID, $idContact: ID) {
    prestationCentiles(idSite: $idSite, idContact: $idContact) {
      id
      etat
      etatColor      
      extension
      denomination
      sda
      email
      label
      loginUser
      isMyistra
      isSoftphone
      devices{
        devicePhysicalId
        model
        port
      }
      client {
        compte {
          id
        }
      }
      site{
        id
        nom
        codePostal
        commune
      }
    }
  }`

const GET_GSM = gql`
  query PrestationGsms($idSite: ID, $idContact: ID) {
    prestationGsms(idSite: $idSite, idContact: $idContact) {
      id
      abonnementLivraisonId
      etat
      etatColor
      numero
      denomination
      forfait
      contact {
        id
        nom
        prenom
        mail
        typeContact
      }
      client {
        compte {
          id
        }
      }
      site{
        id
        nom
        codePostal
        commune
      }
      ligne {
        id
        msisdn
        etat
        affectation
        matricule
        gsmSim {
          simsn
          simsnFull
          pin
          pin2
          puk
          puk2
          fournisseurId
        }
        currentImei
        imeiUpdate
        passwordMessagerie
        commentaire
        commentaireSi
        gsmHno {
          nom
        }
        dateResiliation   
        datePortabilite
        voix
        data
        sms
        mms   
        rioExterne 
        rechargeAuto
        rechargeAutoOption     
      }
    }
  }`


const GET_NDI = gql`
  query PrestationNdis($idSite: ID, $idContact: ID) {
    prestationNdis(idSite: $idSite, idContact: $idContact) {
      id
      etat
      etatColor   
      ndi   
      numero
      nom
      international
      pinCode
      type
      nature
      isTete
      isProvisionne
      codeAnalytique
      client {
        compte {
          id
        }
      }
      site{
        id
        nom
        codePostal
        commune
      }
    }
  }`


const GET_TRUNK = gql`
  query PrestationTrunks($idSite: ID, $idContact: ID) {
    prestationTrunks(idSite: $idSite, idContact: $idContact) {
      id
      etat
      etatColor      
      ip
      nom
      type
      client {
        compte {
          id
        }
      }
      site{
        id
        nom
        codePostal
        commune
      }
    }
  }`

const GET_VGA = gql`
  query PrestationVgas($idSite: ID, $idContact: ID) {
    prestationVgas(idSite: $idSite, idContact: $idContact) {
      id
      etat
      etatColor      
      numero
      sda
      type
      statut
      dateCreation
      dateMiseEnService
      typeLigne
      client {
        compte {
          id
        }
      }
      site{
        id
        nom
        codePostal
        commune
      }
    }
  }`

const GET_VCC = gql`
  query PrestationVccs($idSite: ID, $idContact: ID) {
    prestationVccs(idSite: $idSite, idContact: $idContact) {
      id
      etat
      etatColor
      dateCreation
      tenantId
      resourceId
      nom
      vccId
    }
  }`

const GET_DONNEES_TECHNIQUES_VCC = gql`
  query GetDonneesTechniquesVcc($vccId: ID!) {
    getDonneesTechniquesVcc(vccId: $vccId) {
      nbVm
      stockage
      zoneStockage      
    }    
  }`

const GET_VCD = gql`
  query PrestationVcds($idSite: ID, $idContact: ID) {
    prestationVcds(idSite: $idSite, idContact: $idContact) {
      id
      etat
      etatColor
      dateCreation
      tenantId
      resourceId
      nom
      vcdId
    }
  }`

const GET_DONNEES_TECHNIQUES_VCD = gql`
  query GetDonneesTechniquesVcd($vcdId: ID!) {
    getDonneesTechniquesVcd(vcdId: $vcdId) {
      vm
      vRam
      vCpu
      cpuSpeed
      stockage
      backups
      ipAllocation      
    }
  }`

const GET_PRESTATION_HISTORIQUES = gql`
  query PrestationHistoriques($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput) {
    prestationHistoriques(filters: $filters, sorters: $sorters, pagination: $pagination) {          
      items {
        date
        commentaires
        user {
          prenomNomContact
        }       
        contact{
          nom
          prenom
        }        
      }
    }
  }`

const SAVE_NDI = gql`
  mutation SaveNdi($ndi: NdiInput) {
    saveNdi(ndi: $ndi) {
      ndi        
      nom
      nature
      codeAnalytique      
    }
  }`

const ACTIVE_INTERNATIONAL = gql`
  mutation NdiActiverInternational($ndi: String!, $code: String) {
    ndiActiverInternational(ndi: $ndi, code: $code)
  }`

const DESACTIVE_INTERNATIONAL = gql`
  mutation NdiDesactiverInternational($ndi: String!) {
    ndiDesactiverInternational(ndi: $ndi)
  }`

const SAVE_VCD = gql`
  mutation SaveVcd($vcd: VcdInput) {
    saveVcd(vcd: $vcd) {     
      id         
      nom   
    }
  }`
  
const SAVE_VCC = gql`
  mutation SaveVcc($vcc: VccInput) {
    saveVcc(vcc: $vcc) {              
      id
      nom   
    }
  }`

const TELECHARGER_CDR_NDI = gql`
  query telechargerCdr($ndi: String) {
    telechargerCdr(ndi: $ndi) {          
      hash
      filename
    }
  }`

const TELECHARGER_CDR_GSM = gql`
  query TelechargerCdrGsmLigne($msisdn: String!, $prestationId: ID!) {
    telechargerCdrGsmLigne(msisdn: $msisdn, prestationId: $prestationId) {
      filename
      hash
    }
  }`

const GET_SITE = gql`
 query site($id: ID!) {
    site(id: $id) { 
      id              
      codePostal
      commune
      complementVoie
      libelleVoie
      nom
      raisonSociale
      numeroVoie
      analytique1
      analytique2
      analytique3
      compteId
    }
  }`

const SAVE_SITE = gql`
  mutation SaveSite($site: SiteInput) {
    saveSite(site: $site) {
      id
    }
  }`;

const SAVE_LIGNE = gql`
  mutation SaveLigne($ligne: LigneInput!) {
    saveLigne(ligne: $ligne) {
      id
      affectation
      matricule
      rechargeAuto
      rechargeAutoOption
      commentaireSi
    }
  }`;


const SUBMIT_BON_COMMANDE = gql`
  mutation SubmitBonCommande($bonCommandeInput: BonCommandeInput) {
    submitBonCommande(bonCommandeInput: $bonCommandeInput) {
      message
      success
      codeErreur
    }
  }`;


const SAVE_PRESTATION = gql`
  mutation Mutation($prestation: PrestationInput) {
    savePrestation(prestation: $prestation) {
      success
      message
      prestation {
        id
        contact {
          id
          nom
          mail
          prenom
        }
      }
    }
  }`;


const OPTIONS_LIGNE = gql`
  query OptionsLigne($livraisonId: Int, $filtersPrestation: [FilterInput]) {
    optionsLigne(livraisonId: $livraisonId) {
      data {
        label
        value
      }
      key
      label
      message
      readonly
      type
      value      
      information
    }

    prestations (filters: $filtersPrestation){
      items{
        id
        bdcEnCours { 
          id
          reference
        }
      }
    }
  }`

const OPTIONS_ACTION = gql`
  query OptionsRechargeData($livraisonId: Int, $sorters: [SorterInput], $filtersPrestation: [FilterInput], $filtersSite: [FilterInput]) {    
    optionsRechargeData(livraisonId: $livraisonId) {
      key
      values {
        label
        value
      }
    }

    optionsChangementForfait(livraisonId: $livraisonId) {
      label
      value
      siteId
    }
        
    sites (sorters: $sorters, filters: $filtersSite) { 
      id              
      nom
      codePostal
      commune      
    }

    prestations (filters: $filtersPrestation){
      items{
        id
        bdcEnCours { 
          id
          reference
        }
        abonnement {
          article {
            bonDeCommandeModele {
              modele
              fournisseur
            }
          }
        }
      }
    }
  }`

const BALANCE_INFOS = gql`
query InformationsBalances($livraisonId: Int) {    
  informationsBalances(livraisonId: $livraisonId) {
    label
    value
  }
}`

const VISUALISER_BALANCES = gql`
  query VisualiserBalances($livraisonId: Int) {
    visualiserBalances(livraisonId: $livraisonId) {
      restant
      total
    }
  }`;

const GET_CONTACT_SUPERVISION_SITE = gql`
 query getRoleSupervisionProactiveFromSite($siteId: ID!) {
  getRoleSupervisionProactiveFromSite(siteId: $siteId) { 
      contact {
        id    
        nom
        prenom
        mail          
      }
    }
  }`

const ADD_ROLE_SUPERVISION = gql`
 mutation AddRoleSupervisionProactiveToContact($contactId: ID!, $siteId: ID!) {
  addRoleSupervisionProactiveToContact(contactId: $contactId, siteId: $siteId) {
    success
    message
  }
}`

const UPDATE_LOGIN = gql`
  mutation UpdateLogin($login: String!, $loginInput: LoginInput!) {
    updateLogin(login: $login, loginInput: $loginInput)
  }
`

export {
  GET_SITES,
  GET_PRESTATIONS,
  GET_PRESTATIONS_SITE,
  GET_LIEN,
  GET_CENTILE,
  GET_GSM,
  GET_NDI,
  GET_TRUNK,
  GET_VGA,
  GET_VCC,
  GET_VCD,
  GET_PRESTATION_HISTORIQUES,
  SAVE_NDI,
  ACTIVE_INTERNATIONAL,
  DESACTIVE_INTERNATIONAL,
  TELECHARGER_CDR_NDI,
  TELECHARGER_CDR_GSM,
  GET_SITE,
  SAVE_SITE,
  SAVE_LIGNE,
  SUBMIT_BON_COMMANDE,
  OPTIONS_LIGNE,
  OPTIONS_ACTION,
  SAVE_PRESTATION,
  VISUALISER_BALANCES,
  GET_CONTACT_SUPERVISION_SITE,
  ADD_ROLE_SUPERVISION,
  UPDATE_LOGIN,
  BALANCE_INFOS,
  SAVE_VCC,
  SAVE_VCD,
  GET_DONNEES_TECHNIQUES_VCC,
  GET_DONNEES_TECHNIQUES_VCD,
  GET_PRESTA_CHANGE_SITE,
}