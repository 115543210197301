import React, { useState } from 'react';
import ShellLayout from '../ShellLayout';
import SupportContainer from './SupportContainer';
import { useP } from '../../services/i18n';
import { typeNewTicketType } from './ModaleCreationTicket/ModaleCreationTicket';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCheckFeatureFlag } from '../../Components/CheckFeatureFlag/CheckFeatureFlag';
import Constants from '../../commons/Constants'

function Support() {

  const navigate = useNavigate();

  const p = useP();
  const { checkFeatureFlag } = useCheckFeatureFlag();
  
  const {
    FEATURE_FLAG_SUPPORT_INTERNE,
  } = Constants;

  const breadcrumbDatas = [
    {
      libelle: p.t('navigation.support.label'),
      href: "#",
      current: false
    },
    {
      libelle: p.t('support.tousTickets'),
      href: "#",
      current: true
    }
  ]

  const { ticketId } = useParams();
  const [searchParams] = useSearchParams();

  const availableTypes = ["RÉSEAU", "TÉLÉPHONIE", "HÉBERGEMENT"];
  if (checkFeatureFlag(FEATURE_FLAG_SUPPORT_INTERNE)) {
    availableTypes.push("SUPPORT INTERNE");
  }

  const create = availableTypes.some(e => e === searchParams.get('create')) ? searchParams.get('create') as typeNewTicketType : null;
  const [ticketIdSelected, setTicketIdSelected] = useState<number | null>(ticketId ? parseInt(ticketId) : null)

  const [modaleCreationTicketIsOpen, setModaleCreationTicketIsOpen] = useState(!!create)
  const [typeTicket, setTypeTicket] = useState<typeNewTicketType>(create)

  return <>
    <ShellLayout>
      <SupportContainer
        breadcrumb={breadcrumbDatas}
        ticketIdSelected={ticketIdSelected}
        onCloseModaleDetailTicket={() => {
          navigate("");
          setTicketIdSelected(null);
        }}
        onClickTableRow={(row) => {
          navigate(row.id);
          setTicketIdSelected(row.id);
        }}
        modaleCreationTicketIsOpen={modaleCreationTicketIsOpen}
        onCloseModaleCreationTicket={() => setModaleCreationTicketIsOpen(false)}
        typeNewTicket={typeTicket}
        addTicket={(type: typeNewTicketType) => {
          setModaleCreationTicketIsOpen(true)
          setTypeTicket(type)
        }}
      />
    </ShellLayout>
  </>;
}

export default Support