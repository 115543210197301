import { gql } from "@apollo/client"

const GET_TICKET = gql`
query tickets($filters: [FilterInput], $sorters: [SorterInput], $pagination: PaginationInput, $includes: [IncludeInput]) {
  tickets(
    filters: $filters
    sorters: $sorters,
    pagination: $pagination
    includes: $includes
    ){
      items{
        id
        numero
        titre
        qualificatif
        dateCreation
        activiteTotaleImpactee
        isRecurrent
        ticketCriticite {
          libelle
        } 
        ticketStatut {
          id
          statutPublic
          isClos
        }
        ticketImpact{
          libelle
        }          
        compte {
          raisonSociale
        }
        nomContactSite
        numeroContactSite
        listeEmailsSuiveurs
        sites {
          id
          nom
          codePostal
          commune
          numeroVoie
          libelleVoie
        }
        ticketeurUser {
          id
          nom
          prenom
        }
        referenceClient
        ticketService {
          libelle
        }
        prestations {
          libelle
        }
        client {
          numcli
          nomContrat      
        } 
        ticketPrecedent {
          id
          numero
          titre
        }
        ticketRating {
          id
          rating
          commentaire
          isModifiableClient
        }
      }  
    }
  }`


const GET_TICKET_TOKEN = gql`
  query TicketByToken($token: String!) {
    ticketByToken(
      token: $token
      ){
        id
        numero
        titre
        qualificatif
        dateCreation
        activiteTotaleImpactee
        isRecurrent
        ticketCriticite {
          libelle
        } 
        ticketStatut {
          id
          statutPublic
          isClos
        }
        ticketImpact{
          libelle
        }          
        compte {
          raisonSociale
        }
        nomContactSite
        numeroContactSite
        listeEmailsSuiveurs
        sites {
          id
          nom
          codePostal
          commune
          numeroVoie
          libelleVoie
        }
        ticketeurUser {
          id
          nom
          prenom
        }
        referenceClient
        ticketService {
          libelle
        }
        prestations {
          libelle
        }
        client {
          numcli
          nomContrat      
        } 
        ticketPrecedent {
          id
          numero
          titre
        }
        ticketRating {
          id
          rating
          commentaire
          isModifiableClient
        }
      }  
    }`;



export {
  GET_TICKET,
  GET_TICKET_TOKEN,
}